import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  Text,
  IconButton,
  Link,
  Image,
  Box
} from '@chakra-ui/react';
import { MdClose, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAutenticacao } from './hook';

const LoginModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { logado, login } = useAutenticacao();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    setError(null);
    console.log("Tentando fazer login...");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("E-mail inválido. Por favor, insira um e-mail válido.");
      return;
    }

    try {
      const response = await axios.post("http://150.165.85.108:8000/usuarios/login", { email, senha });

      if (response.status === 200) {
        login(); // Atualiza o estado de autenticação
        console.log("Login bem-sucedido.");
        //navigate('/home'); // Adiciona um pequeno atraso
        window.location.href = '/home';
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Problemas de login: email ou senha incorretos");
      } else {
        setError("Erro desconhecido");
      }
    }
  };

  const handlePerdeuSenha = async () => {
    setError(null);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("E-mail inválido. Por favor, insira um e-mail válido.");
      return;
    }

    try {
      const response = await axios.post("http://127.0.0.1:8000/usuarios/resetar-senha/", { email });

      if (response.status === 200) {
        navigate('/');
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Erro desconhecido");
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.8)" backdropFilter="blur(10px)" />
      <ModalContent bg="white">
        <IconButton
          aria-label="Fechar"
          icon={<MdClose />}
          onClick={onClose}
          position="absolute"
          top="1rem"
          right="1rem"
          background="transparent"
          size="sm"
        />
        <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
          <Image
            src='/logos/oecc_horizontal.svg'
            alt="Logo"
            width={{ base: '80%', sm: '60%', md: '40%' }}
            maxWidth="300px"
            mx="auto"
            mt={2}
          />
        </ModalHeader>

        <ModalBody fontWeight="bold">
          <Text>E-mail</Text>
          <Input
            type='email'
            placeholder="Insira seu e-mail"
            mb={4}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Text>Senha</Text>
          <Box style={{ position: 'relative' }} alignSelf={'center'}>
            <Input
              placeholder="Insira sua senha"
              type={showPassword ? "text" : "password"}
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
            <IconButton
              aria-label={showPassword ? "Esconder senha" : "Mostrar senha"}
              icon={showPassword ? <MdVisibilityOff /> : <MdVisibility />}
              onClick={() => setShowPassword(!showPassword)}
              position="absolute"
              right="0"
              top="30%"
              transform="translateY(-50%)"
              background="transparent"
              border="none"
              p={2}
              m={2}
              zIndex={1}
            />
          </Box>

          {error && <Text color={"red.500"} mt={2}>{error}</Text>}

          <Link onClick={handlePerdeuSenha} color="grey.500" mt={4} display="block" fontSize={'12px'}>
            Esqueceu a senha? Clique aqui para solicitar uma nova.
          </Link>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button bg="#f68733" color="white" onClick={handleLogin}>
            Entrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default LoginModal;
