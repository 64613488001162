import React, { useState } from "react";
import {
    Box,
    Link,
    useDisclosure,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import MudarSenhaModal from "./MudarSenha";

const MobileMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeModal, setActiveModal] = useState(null);

    const openModal = (modalName) => {
        setActiveModal(modalName);
        onOpen();
    };
    return (
        <>
            <Box display={{ base: "block", lg: "none" }} zIndex="8">
                <Flex
                    bg="rgb(141, 196, 46)"
                    width="100%"
                    height="50px"
                    padding="0 20px"
                    justifyContent="space-between"
                    alignItems="center"
                    position={"fixed"}
                    zIndex={4}
                >
                    <Link href="#oecc">
                        <Image
                            loading="lazy"
                            src="\logos\brancooecc_horizontal 1.svg"
                            w="160px"
                        />
                    </Link>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<HamburgerIcon color={"white"} />}
                            variant="outline"
                            backgroundColor={"#F5811E"}
                            borderStyle={"none"}
                            boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)"
                            _expanded={{ bg: "#F5811E" }}
                            _hover={{ bg: "#F5811E" }}
                            width="40px" // Adjusted width
                            height="40px" // Adjusted height
                            padding="0" // Remove padding
                        />
                        <MenuList backgroundColor={"#FF8700"} color={"white"}>
                            <MenuItem
                                backgroundColor={"#FF8700"}
                                _hover={{
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)"
                                }} onClick={() => openModal("mudarSenha")}
                            >
                                <Link href="#edicoes"
                                    _hover={{ textDecoration: "none" }}
                                    fontWeight={"bold"}
                                >
                                    MUDAR SENHA
                                </Link>
                            </MenuItem>
                            <MenuItem
                                backgroundColor={"#FF8700"}
                                _hover={{
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)"
                                }}>
                                <Link href="#materialapoio"
                                    _hover={{ textDecoration: "none" }}
                                    fontWeight={"bold"}>
                                    SAIR
                                </Link>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Box>
            {activeModal === "mudarSenha" && (
                <MudarSenhaModal isOpen={isOpen} onClose={onClose} />
            )}
        </>
    );
};

export default MobileMenu;