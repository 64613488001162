import React from 'react';
import { Heading, VStack, Text } from '@chakra-ui/react';
import Carrossel from './Carrossel';
import CarrosselVideos from './CarrosselVideos';

const ConsumoConsciente = () => {
    return (
        <VStack gap={10} justify="center" align={'center'} marginLeft={{ base: '0', lg: '10%' }} marginRight={{ base: '0', lg: '10%' }}>
            <Heading fontSize={['28px', '42px']} color="#8DC42E" align={'center'}>
            Como se tornar um consumidor consciente?            </Heading>
            <Text fontSize={['14px', '16px', '18px']} align={'center'} textAlign={["justify", "center"]}>
                Adotar hábitos de consumo consciente faz a diferença sob vários aspectos. 
                Seja no meio ambiente, reduzindo os impactos ambientais causados pelo que consumimos, 
                seja nas nossas finanças, gerando economia. Para te auxiliar, elaboramos uma série 
                de dicas com hábitos que vão te ajudar a consumir de forma mais sustentável, proporcionando 
                um equilíbrio entre sua satisfação pessoal, os impactos ambientais de longo prazo e 
                os efeitos sociais e financeiros de sua decisão.
                Ao colocar esses hábitos em prática, você será um colecionador de medalhas, fazendo a diferença todos os dias!
            </Text>
            <Carrossel />
            <Heading fontSize={['28px', '42px']} color="#8DC42E" align={'center'}>
                Vamos assistir a alguns vídeos?
            </Heading>
            <Text fontSize={['14px', '16px', '18px']} align={'center'} textAlign={["justify", "center"]}>Assista os vídeos a seguir, eles irão te auxiliar na compreensão sobre energia elétrica e como ser um consumidor
                consciente.
            </Text>
            <CarrosselVideos />
        </VStack>
    );
};

export default ConsumoConsciente;
