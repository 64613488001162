import React from 'react';
import { Flex, RadioGroup, Radio, Box } from '@chakra-ui/react';

const gerarOpcaoDeLetra = (index) => {
    return String.fromCharCode(65 + index); // 65 é o código ASCII para 'A'
};

const Opcoes = ({ options, selectedOption, onOptionChange }) => (
    <RadioGroup onChange={onOptionChange} value={selectedOption}>
        <Flex direction="column">
            {options.map((option, index) => (
                <Radio
                    key={index}
                    value={option}
                >
                    <Box fontSize="20px">
                        {`${gerarOpcaoDeLetra(index)}. ${option}`} {/* Adiciona a letra gerada dinamicamente antes da opção */}
                    </Box>
                </Radio>
            ))}
        </Flex>
    </RadioGroup>
);

export default Opcoes;
