import React from "react";
import { Flex, Text, Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconeTexto = ({ icon, text}) => {
    return (
        <Flex alignItems="center" mb={2}>
            <Icon as={FontAwesomeIcon} icon={icon} mr={1} fontSize="14px" />
            <Text fontSize="14px"> {text} </Text>
        </Flex>
    )
};
export default IconeTexto;