import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import YouTube from 'react-youtube';

const SlideVideos = ({ video, isActive }) => {
  const { youtubeId, title, fonte } = video;

  return (
    <Box
      w="800px"
      h="300px"
      position="relative"
      display={isActive ? 'block' : 'none'}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      overflow="hidden"
    >
      {isActive && (
        <Box bg="white" p={4} height="100%" display="flex" flexDirection="column" justifyContent="center">
          <Text fontWeight="bold" fontSize="24px" textAlign="center" mb={4}>
            {title}
          </Text>
          <YouTube
            videoId={youtubeId}
            align='center'
            opts={{
              width: '100%',
              height:'200px',
              playerVars: {
                autoplay: 0,
                controls: 1,
                modestbranding: 1,
                rel: 0,
              },
            }}
          />
          <Text fontWeight="bold" fontSize="12px" textAlign="center" margin={2}>
            Fonte: {fonte}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default SlideVideos;
