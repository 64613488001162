import React, { useState } from "react";
import { Box, Heading, HStack, useBreakpointValue } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import VencedoresTrofeu from "./VencedoresTrofeu";
import vencedores from './vencedores.json';  // Importando o arquivo JSON

const Vencedores = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === vencedores.length - 1 ? 0 : prevIndex + 1));
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? vencedores.length - 1 : prevIndex - 1));
    };

    const isMobile = useBreakpointValue({ base: true, lg: false });

    return (
        <Box id="edicoes" p={[4, 0]} marginLeft={{ base: '5%', md: '20%', lg: '10%' }} marginRight={{ base: '5%', md: '20%', lg: '10%' }}>
            <Heading fontSize={['42px', '42px']} color={"#8DC42E"} mb={['8']} display='grid' align='center'>
                Vencedores
            </Heading>
            <Box
                p={isMobile ? "10px" : [6, 10]} // Tamanho fixo no modo mobile, responsivo no desktop
            >
                {isMobile ? (
                    <HStack align="center"  justify="space-between">
                        <ChevronLeftIcon
                            onClick={prevSlide}
                            color="#F5811E"
                            fontSize='42px'
                        />
                        <VencedoresTrofeu {...vencedores[currentIndex]} />
                        <ChevronRightIcon
                            onClick={nextSlide}
                            color="#F5811E"
                            fontSize='42px'
                        />
                    </HStack>
                ) : (
                    <HStack align="center" justify="space-between" spacing={10}>
                        {vencedores.map((vencedor, index) => (
                            <VencedoresTrofeu key={index} {...vencedor} />
                        ))}
                    </HStack>
                )}
            </Box>
        </Box>
    );
};

export default Vencedores;
