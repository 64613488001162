import Oecc from './components/Oecc/Oecc';
import EdicoesAnteriores from './components/EdicoesAnteriores/EdicoesAnteriores';
import Objetivos from './components/Oecc/Objetivos';
import MaterialDeApoio from './components/MaterialDeApoio/MaterialDeApoio';
import Footer from './components/Footer/Footer';
import Chamada from './components/Oecc/Chamada';
import ComoFunciona from './components/Oecc/ComoFunciona';
import Vencedores from './components/EdicoesAnteriores/Vencedores/Vencedores';
import Menu from './components/Menu/Menu';
import Formulario from './components/Contato/Formulario';


function LandingPage() {
    return (
        <>
            <Menu />
            <Oecc />
            <Objetivos />
            <ComoFunciona />
            <Chamada />
            <EdicoesAnteriores />
            <Vencedores />
            <MaterialDeApoio />
            <Formulario/>
            <Footer />
        </>
    );
}

export default LandingPage;
