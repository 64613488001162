import React from 'react';
import { Box, Heading, Text, HStack, VStack } from '@chakra-ui/react';
import { MdLooksOne, MdLooksTwo } from 'react-icons/md';


const Fases = () => {
    return (
        <VStack alignItems={['center', 'start']}>
            <Text fontSize={['18px']} alignItems="flex-start" marginLeft={{ base: '6%', lg: '0' }} marginRight={{ base: '6%', lg: '0' }}>
                A OECC é organizada em duas fases, assim definidas:
            </Text>
            <HStack alignItems="flex-start" marginLeft={{ base: '6%', lg: '0' }} marginRight={{ base: '6%', lg: '0' }}>
                <Box as={MdLooksOne} size="28px" color="#F5811E" />
                <Box flex="1">
                    {/* A OECC avalia os conhecimentos dos competidores com base em padrões internacionais sobre a temática de consumo consciente, no tocante às ações de conscientização para o combate ao desperdício de água, de energia elétrica e de alimentos, bem como o descarte adequado de resíduos. */}
                    <Text align="justify" fontSize={['14px']} fontWeight={'bold'}>
                        Aplicação de um exame inicial, abrangendo 30 (trinta) questões que envolverá a temática de consumo consciente, abrangendo o dia a dia do(a) estudante.
                    </Text>
                    <Text fontSize={"12px"} align="justify"> Online | Duração de 60 (sessenta) minutos | Caráter eliminatório | 120 (cento e vinte) mais bem classificados(as) seguem para a segunda fase.</Text>
                    <Text fontSize={"12px"} align="justify" fontWeight={'bold'}> Data: 28 a 31 de outubro de 2024 | Conforme disponibilidade do(a) estudante.</Text>
                </Box>
            </HStack>

            <HStack alignItems="flex-start" marginLeft={{ base: '6%', lg: '0' }} marginRight={{ base: '6%', lg: '0' }}>
                <Box as={MdLooksTwo} size="28px" color="#F5811E" />
                <Box flex="1">
                    <Text align="justify" fontSize={'14px'} fontWeight={'bold'}>
                        Aplicação de um exame final, abrangendo 30 (trinta) questões que envolverá a temática de consumo consciente, abrangendo o dia a dia do(a) estudante.
                    </Text>
                    <Text fontSize={"12px"} align="justify"> Presencial nas cidades de Campina Grande, João Pessoa, Patos e Cajazeiras | Duração de 60 (sessenta) minutos | Caráter classificatório | 30 (trinta) mais bem classificados(as) serão homenageados com as medalhas de ouro, prata e bronze | 5 (cinco) mais bem classificados(as) receberão prêmios em dinheiro, conforme regulamento.</Text>
                    <Text fontSize={"12px"} align="justify" fontWeight={'bold'}> Data: 30 de novembro de 2024, às 14h00 | Nas cidades de Campina Grande, Cajazeiras, João Pessoa e Patos.</Text>
                </Box>
            </HStack>

        </VStack>

    );
};

export default Fases;
