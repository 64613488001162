import React from 'react';
import { Text } from '@chakra-ui/react';
// Enunciado da questão
const ContaQuestao = ({ questionNumber, statement, media }) => (
    <Text
        mb={4}
        mt={!media ? 250 : 0} // Aplica margin-top somente se não houver mídia
        fontSize={{ base: '24px', md: '29px' }} // Define tamanhos de fonte diferentes para dispositivos pequenos e grandes
    >
        {`Questão ${questionNumber}: ${statement}`}
    </Text>
);

export default ContaQuestao;
