import React from 'react';
import { Box, Image, Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import { keyframes } from '@emotion/react'; // Importar keyframes do @emotion/react

// Define a função para escolher a imagem com base no lugar
const getTrophyImage = (lugar) => {
    switch (lugar) {
        case '1º Lugar':
            return '/medalhas/1ouro.png'; // Imagem para 1º lugar
        case '2º Lugar':
            return '/medalhas/2prata.png'; // Imagem para 2º lugar
        case '3º Lugar':
            return '/medalhas/3bronze.png'; // Imagem para 3º lugar
        default:
            return '/medalhas/default.png'; // Imagem padrão caso não haja correspondência
    }
};

// Define a animação de rotação
const rotateAnimation = keyframes`
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
`;

const VencedoresTrofeu = ({ nome, escola, premio, lugar }) => {
    return (
        <Box
            width={{ base: "280px", lg: "300px" }} // Define largura fixa
            height={{ base: "280px", lg: "300px" }} // Define altura fixa
            p={4}
            borderRadius="20px"
            boxShadow="md"
            bgGradient="linear(to-b, #a1d64d, #6e9e23)"
            position="relative" // Adiciona position relative para o contêiner
            overflow="hidden" // Adiciona overflow hidden para garantir que nada saia do card
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="center"
        >
            <Image
                loading="lazy" src={getTrophyImage(lugar)}
                w={{ base: '50px', md: '60px', lg: '70px' }}
                mb={2}
                position="absolute"
                top="0px" // Ajusta o valor conforme necessário
                left="10px" // Ajusta a posição horizontal se necessário
                animation={`${rotateAnimation} 5s infinite`}
                _hover={{
                    transform: { base: "none", md: "none", lg: "scale(1.2)" },
                    transition: "transform 0.3s ease"
                }}
            />
            <Flex direction="column" align="flex-start" pt={{ base: '70px', md: '90px' }}> {/* Adiciona padding-top para evitar sobreposição */}
                <Text
                    fontSize="16px"
                    color="#FFFFFF"
                    mb={1}
                >
                    {lugar}
                </Text>
                <Text
                    fontSize={{ base: "22px", lg: "28px" }}
                    fontWeight="bold"
                    color="#FFFFFF"
                    mb={1}
                >
                    {nome}
                </Text>
                <Text
                    fontSize="16px"
                    color="#FFFFFF"
                    mb={1}
                >
                    {escola}
                </Text>
                <Flex w="full" justify="flex-end">
                    <Text
                        fontSize="16px"
                        fontWeight="bold"
                        color="#FFFFFF"
                    >
                        {premio}
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
};

export default VencedoresTrofeu;
