import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Input,
    Text,
    Heading,
    IconButton,
    Box
} from '@chakra-ui/react';
import { MdClose, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MudarSenhaModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    const [showSenha, setShowSenha] = useState(false);
    const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
    const [error, setError] = useState(null);

    const handleNovaSenha = async () => {
        setError(null);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError("E-mail inválido. Por favor, insira um e-mail válido.");
            return;
        }

        if (senha !== confirmarSenha) {
            setError("As senhas não coincidem. Por favor, verifique e tente novamente.");
            return;
        }

        try {
            const response = await axios.post("http://127.0.0.1:8000/usuarios/mudar-senha/", {
                email: email,
                senha: senha,
            });
            if (response.status === 200) {
                navigate('/home');
                onClose();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError("Erro desconhecido");
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bg="rgba(0, 0, 0, 0.8)" backdropFilter="blur(10px)" />
            <ModalContent bg="white">
                <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
                    <Heading fontSize="24px">Mudar senha</Heading>
                    <MdClose onClick={onClose} size={18} cursor={'pointer'} />
                </ModalHeader>

                <ModalBody fontWeight="bold">
                    <Text>E-mail</Text>
                    <Input
                        placeholder="Insira seu e-mail"
                        mb={4}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Text>Nova senha</Text>
                    <Box position="relative">
                        <Input
                            placeholder="Insira sua nova senha"
                            type={showSenha ? "text" : "password"}
                            mb={4}
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                        />
                        <IconButton
                            aria-label={showSenha ? "Esconder senha" : "Mostrar senha"}
                            icon={showSenha ? <MdVisibilityOff /> : <MdVisibility />}
                            onClick={() => setShowSenha(!showSenha)}
                            position="absolute"
                            right="0"
                            top="30%"
                            transform="translateY(-50%)"
                            background="transparent"
                            border="none"
                            p={2}
                            zIndex={1}
                        />
                    </Box>

                    <Text>Confirme sua senha</Text>
                    <Box position="relative">
                        <Input
                            placeholder="Digite novamente sua senha"
                            type={showConfirmarSenha ? "text" : "password"}
                            mb={4}
                            value={confirmarSenha}
                            onChange={(e) => setConfirmarSenha(e.target.value)}
                        />
                        <IconButton
                            aria-label={showConfirmarSenha ? "Esconder senha" : "Mostrar senha"}
                            icon={showConfirmarSenha ? <MdVisibilityOff /> : <MdVisibility />}
                            onClick={() => setShowConfirmarSenha(!showConfirmarSenha)}
                            position="absolute"
                            right="0"
                            top="30%"
                            transform="translateY(-50%)"
                            background="transparent"
                            border="none"
                            p={2}
                            zIndex={1}
                        />
                    </Box>

                    {error && <Text color={"red.500"} mt={2}>{error}</Text>}
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button bg="#f68733" color="white" onClick={handleNovaSenha}>
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default MudarSenhaModal;
