import React from 'react';
import { Box, Image } from '@chakra-ui/react';

const Slide = ({ image, index, currentIndex, totalSlides }) => {
  const isCurrent = index === currentIndex;
  const isPrev = (index === (currentIndex - 1 + totalSlides) % totalSlides);
  const isNext = (index === (currentIndex + 1) % totalSlides);

  let transform;
  if (isCurrent) {
    transform = 'translateX(0)';
  } else if (isPrev) {
    transform = 'translateX(-100%)';
  } else if (isNext) {
    transform = 'translateX(100%)';
  } else {
    transform = 'translateX(200%)';
  }

  return (
    <Box
      position="absolute"
      transition="all 0.5s"
      transform={transform}
      filter={isCurrent ? 'none' : 'blur(5px)'}
      zIndex={isCurrent ? 1 : 0}
      opacity={isCurrent ? 1 : 0.5}
      visibility={isCurrent || isPrev || isNext ? 'visible' : 'hidden'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      w="100%"
      h="100%"
    >
      <Image src={image.src} width="100%" height="100%" objectFit="cover" />
    </Box>
  );
};

export default Slide;