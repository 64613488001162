import { Button } from '@chakra-ui/react';
import React from 'react';

const NavigationButton = ({ direction, onClick, icon }) => {
  return (
    <Button
      onClick={onClick}
      variant="unstyled"
      color="#F5811E"
      _hover={{ color: "#F5811E" }}
      _active={{ color: "#F5811E" }}
      _focus={{ outline: "none" }}
    >
      {icon}
    </Button>
  );
};

export default NavigationButton;
