import React from 'react';
import { Heading, HStack, VStack, Flex, AspectRatio } from '@chakra-ui/react';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import IconeTexto from './IconeTexto';

const Localizacao = () => {
  return (
    <HStack justify="left">
      <VStack align="start" spacing={4}>
      <Heading fontSize={'20px'} alignSelf="flex-start">
          Localização
        </Heading>
        <AspectRatio ratio={16 / 9} w="100%" h={'280px'}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.2373447067735!2d-35.911743523943635!3d-7.213743992792054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ac1e2c99b574e7%3A0x46910f89afdda1e3!2sR.%20Apr%C3%ADgio%20Veloso%2C%20822%20-%20Universit%C3%A1rio%2C%20Campina%20Grande%20-%20PB%2C%2058429-900!5e0!3m2!1spt-BR!2sbr!4v1723574316451!5m2!1spt-BR!2sbr"
            allowFullScreen
            loading="lazy"
            style={{ border: 0, width: '100%', height: '100%' }}
          />
        </AspectRatio>
        <Flex direction="column" align="flex-start">
          <IconeTexto icon={faLocationDot} text="Rua Aprígio Veloso, 882 - Bairro Universitário - CEP: 58.429-900 - Campina Grande - Paraíba - Brasil" />
          <IconeTexto icon={faPhone} text="+55 (83) 2101-1734" />
          <IconeTexto icon={faEnvelope} text="ocee@dee.ufcg.edu.br" />
        </Flex>
      </VStack>
    </HStack>
  );
};

export default Localizacao;
