import React, { useState } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import SlideVideos from './SlideVideos';

const videos = [
  { title: 'Tipos de energia', youtubeId: 'YXKLna8zboY', fonte: "Smile and Learn" },
  { title: 'Como funciona a energia solar', youtubeId: 'KA_uZ9otOlc', fonte: "Intelbras" },
  { title: 'Energias renováveis e não renováveis', youtubeId: '6r0EgxExbEU', fonte: "Smile and Learn" },
];

const CarrosselVideos = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = videos.length;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  return (
    <Flex direction="column" align="center" w="80%" maxW="1200px" m="0 auto">
      <HStack alignItems="center" justifyContent="center" w="100%" minW="360px" px={4} py={2}>
        <ChevronLeftIcon onClick={prevSlide} fontSize={{ base: '26px', md: '26px', lg: '70px' }} color="#F5811E" />
        {videos.map((video, index) => (
          <SlideVideos key={index} video={video} isActive={index === currentIndex} />
        ))}
        <ChevronRightIcon onClick={nextSlide} fontSize={{ base: '26px', md: '26px', lg: '70px' }} color="#F5811E" />
      </HStack>
      <Flex mt={4}>
        {videos.map((_, index) => (
          <Text
            key={index}
            fontSize="20px"
            mx={1}
            cursor="pointer"
            color={index === currentIndex ? '#F5811E' : 'black'}
            onClick={() => setCurrentIndex(index)}
          >
            &bull;
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};

export default CarrosselVideos;
