import React from 'react';
import { Box, Text, Heading, Image } from '@chakra-ui/react';
import DesktopMenu from './DesktopMenu';
import Menu from './Menu';

const EnvioRealizado = ({ nomeDoAluno }) => {
    return (
    <>
        <Menu />
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" p={[8, 12]} bgColor={'#f0f0f0'} flexDirection={'column'}>
            <Image
                loading="lazy" src="/imagens/Happy student-cuate.svg"
                w={{ base: '100%', md: '600px', lg: '450px' }}
                mb={{ base: '4', md: '0' }}
            />
            <Heading fontSize={['38px', '50px']} color="#323C33" marginBottom={'10'} textAlign={'center'}>
                Envio realizado com sucesso!
            </Heading>
            <Text ml={[0, 12]} mr={[0, 12]} align={['justify', 'center']} fontSize={['16px', '24px']} >Parabéns {nomeDoAluno}! Sua prova foi recebida em nosso sistema. Agradecemos por sua participação e seu resultado estará publicado nesta página no dia 00/00/00 às 00h00m.</Text>
        </Box>
    </>

    );
}

export default EnvioRealizado;
