import React from 'react';
import { Box, Text, HStack, Button, VStack, Image, Link, useDisclosure } from '@chakra-ui/react';
import LeiaMais from './LeiaMais'; // Importe o novo componente

const Oecc = () => {
    const { isOpen, onOpen, onClose } = useDisclosure(); // Hook para controlar o modal

    return (
        <Box id="oecc" p={[4, 12]} marginLeft={{ base: '0', lg: '10%' }} marginRight={{ base: '0', lg: '10%' }}>
            <HStack gap={10} justify="center" flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" marginTop={20}>
                <Image 
loading="lazy"                    src="\imagens\GLOBO.svg"
                    w={{ base: '70%', md: '500px', lg: '440px' }}  // Ajuste o tamanho da imagem para ocupar a largura total em telas pequenas e um tamanho fixo em telas maiores
                    mb={{ base: '4', md: '0' }}  // Margem inferior para separar a imagem do texto em telas pequenas
                />
                <VStack align="start" spacing={5} flex="1" pl={[6, 0]} pr={[6, 0]}>
                    <Text fontSize={['14px', '16px', '20px']} align="justify">
                    A Olimpíada Educacional do Consumo Consciente (OECC), uma competição técnica/científica, é um projeto organizado pela UFCG, com o apoio do IFPB. A OECC tem como foco a apresentação de material instrucional e atividades acadêmicas sobre o consumo consciente, tanto para a redução dos consumos de água, energia elétrica e alimentos, quanto ao impacto ambiental do consumismo. A OECC tem como público-alvo estudantes do 8º e 9º ano do ensino fundamental das escolas públicas e privadas do Estado da Paraíba.
                    </Text>
                    <HStack spacing={4} mt={4}>
                        <Link href="https://forms.gle/TtLa5amSaEC6NfuJA" isExternal>
                            <Button
                                color={'white'}
                                background={"#1275bb"}
                                width={['100%', '20em']}
                                alignSelf={['center', 'start']}
                                _hover={{ bgGradient: "linear(to-r, blue.300, blue.500)" }}
                                borderRadius={'40px'}
                                mb={[10, 0]}
                            >
                                Inscreva-se
                            </Button>
                        </Link>
                        <Button
                            color={'white'}
                            background={"#F5811E"}
                            width={['100%', '20em']}
                            alignSelf={['center', 'start']}
                            _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                            borderRadius={'40px'}
                            mb={[10, 0]}
                            onClick={onOpen} // Abre o modal
                        >
                            Leia Mais
                        </Button>
                    </HStack>
                </VStack>
            </HStack>

            {/* Usar o componente de modal */}
            <LeiaMais isOpen={isOpen} onClose={onClose} />
        </Box>
    );
};

export default Oecc;
