import React from 'react';
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';

const Slide = ({ image }) => {
    const [title, content] = image.caption.split(':\n');

    return (
        <Box
            w={{ base: '200px', md: '220px', lg: '240px' }}
            h="300px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            borderRadius="10px"
            overflow="hidden"
            flexShrink={0} // Para garantir que os slides não encolham
            bg="#F5811E"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            textColor={'white'}
            _hover={{
                bg: 'white', // Cor de fundo ao passar o mouse
                color: '#F5811E', // Cor do texto ao passar o mouse
                transform: "scale(1.05)", // Aumenta o tamanho do card ao passar o mouse
                border: '2px solid #F5811E', // Adiciona uma borda ao box
            }}>
            <Image 
loading="lazy"                src="\imagens\medalha_oecc.png" // Ajuste o caminho da imagem conforme necessário
                w={'50px'}
                alignSelf={'left'}
                pt={0}
                pl={4}
            ></Image>
            <VStack p={4}>
                <Text fontWeight="bold" fontSize={'18px'} textAlign="left" mb={4}>
                    {title}
                </Text>
                <Text
                    whiteSpace="pre-wrap"
                    textAlign="left"
                    fontSize={'12px'}
                >
                    {content}
                </Text>
            </VStack>

        </Box>

    );
};

export default Slide;
