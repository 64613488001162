import React from 'react';
import { Heading, HStack, Image, VStack, } from '@chakra-ui/react';

const Realizacao = () => {
    return (
        <HStack gap={10} justify="left" mb={'2em'}>
            <VStack align="start" spacing={4}>
                <Heading fontSize={'20px'} alignSelf="flex-start">
                    Realização
                </Heading>
                <Image src="\UFCG-lateral.png" w={'12em'}></Image>
            </VStack>
        </HStack>
    );
};

export default Realizacao;
