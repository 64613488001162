import React, { memo } from 'react';
import { Grid, Heading, Box, Text, useBreakpointValue } from '@chakra-ui/react';
import equipe from "./equipe.json";

const Equipe = () => {
    // Organiza os membros em categorias
    const orientadores = equipe.filter(membro => membro.cargo.includes('Orientador'));
    const desenvolvedores = equipe.filter(membro => membro.cargo.includes('Desenvolvedora'));
    const extensionistas = equipe.filter(membro => membro.cargo.includes('Estudante extensionista')
        && !membro.cargo.includes("Desenvolvedora"));
    const logotipoDesign = equipe.filter(membro => membro.cargo.includes("Designer"));

    // Calcula o número de colunas para a grade
    const numColumns = useBreakpointValue({ base: 1, sm: 1, md: 2, lg: 2 });

    return (
        <Box>
            <Heading fontSize={'20px'} alignSelf="flex-start" mb='1em'>
                Nossa Equipe
            </Heading>
            <Grid templateColumns={`repeat(${numColumns}, 1fr)`} gap={6}>
                {/* Coluna dos Orientadores e Desenvolvedores */}
                <Box>
                    <Heading fontSize={'16px'} alignSelf="flex-start" mb='1em'>
                        Coordenadores
                    </Heading>
                    {orientadores.map((membro, index) => (
                        <Box key={`orientador-${index}`} mb="1em">
                            <Text fontSize={'12px'}>{membro.nome}</Text>
                        </Box>
                    ))}
                    <Heading fontSize={'16px'} alignSelf="flex-start" mb='1em'>
                        Desenvolvedoras
                    </Heading>
                    {desenvolvedores.map((membro, index) => (
                        <Box key={`desenvolvedor-${index}`} mb="1em">
                            <Text fontSize={'12px'}>{membro.nome}</Text>
                        </Box>
                    ))}
                </Box>

                {/* Coluna dos Estudantes Extensionistas */}
                <Box>
                    <Heading fontSize={'16px'} alignSelf="flex-start" mb='1em'>
                        Estudantes Extensionistas
                    </Heading>
                    {extensionistas.map((membro, index) => (
                        <Box key={`extensionista-${index}`} mb="1em">
                            <Text fontSize={'12px'}>{membro.nome}</Text>
                        </Box>
                    ))}
                    <Heading fontSize={'16px'} alignSelf="flex-start" mb='1em'>
                        Designer
                    </Heading>
                    {logotipoDesign.map((membro, index) => (
                        <Box key={`desenvolvedor-${index}`} mb="1em">
                            <Text fontSize={'12px'}>{membro.nome}</Text>
                        </Box>
                    ))}
                </Box>
            </Grid>
        </Box>
    );
};

export default Equipe;
