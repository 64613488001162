import React, { useRef, useState } from 'react';
import { Text, Flex, Image, VStack, Button, Input, Heading, Textarea, Link, HStack, useToast } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { sendEmail } from './emailService'; // Importe a função de envio

const Formulario = () => {
    const form = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        message: '',
    });
    const toast = useToast(); // Hook para mostrar notificações

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail(form, setIsSubmitting, toast); // Use a função de envio

        setFormData({
            user_name: '',
            user_email: '',
            message: ''
        })
    };

    const isFormValid = () => {
        return formData.user_name && formData.user_email && formData.message;
    };

    return (
        <Flex
            direction="column"
            justify="space-between"
            align="center"
            id="contato"
            p={{ base: 4, md: 6 }}
        >
            <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="center"
                align="center"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.3)"
                borderRadius={10}
                backgroundColor="#FFFFFF"
                p={6}
                margin={{ base: 4, md: 10 }}
                width={{ base: '90%', md: '80%', lg: '50%' }}
                maxW="1200px"
                spacing={{ base: 4, md: 8 }}
                alignItems="center"
            >
                <Image 
loading="lazy"                    src="/imagens/Work chat-cuate.svg"
                    w={{ base: '80%', md: '50%', lg: '40%' }}
                    mr={4}
                    alignSelf="center"
                />
                <VStack
                    as="form"
                    ref={form}
                    onSubmit={handleSubmit}
                    align={{ base: 'center', lg: 'flex-start' }}
                    spacing={4}
                    fontWeight="bold"
                    w={{ base: '100%', md: '70%' }}
                >
                    <Heading color="#F38421">Fala com a gente</Heading>
                    <Text fontWeight="normal">Alguma dúvida ou comentário?</Text>
                    <Input
                        placeholder="Nome"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleChange}
                        borderColor="gray.400"
                        focusBorderColor='orange.300'
                        w="100%"
                    />

                    <Input
                        placeholder="Email"
                        name="user_email"
                        value={formData.user_email}
                        onChange={handleChange}
                        borderColor="gray.400"
                        focusBorderColor='orange.300'
                        w="100%"
                    />
                    <Textarea
                        placeholder="Escreva sua mensagem"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        borderColor="gray.400"
                        focusBorderColor='orange.300'
                        w="100%"
                        h="160px"
                    />
                    <HStack
                        justify="space-between"
                        w="100%"
                        alignSelf="center"
                    >
                        <Link href="https://www.instagram.com/oecc_ufcg/" isExternal>
                            <FaInstagram size="2.3em" color="#F38421" />
                        </Link>
                        <Button
                            bg="#F5811E"
                            color="white"
                            borderRadius="10px"
                            px={8}
                            py={4}
                            fontSize={{ base: '12px', sm: '14px', md: '16px' }}
                            type="submit"
                            isLoading={isSubmitting} // Adiciona o estado de carregamento
                            isDisabled={!isFormValid()} // Desabilita o botão se o formulário não estiver completo
                        >
                            Enviar
                        </Button>
                    </HStack>
                </VStack>
            </Flex>
        </Flex>
    );
};

export default Formulario;
