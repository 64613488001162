import React from "react";
import {
    Box,
    Link,
    useDisclosure,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import LoginModal from "../Login/Login";

const MobileMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Agora `menuItems` é criado dentro do componente, onde `onOpen` está definido
    const menuItems = [
        { label: "EDIÇÕES ANTERIORES", href: "#edicoes" },
        { label: "MATERIAL DE APOIO", href: "#materialapoio" },
        { label: "CONTATO", href: "#contato" },
        { label: "LOGIN", onClick: onOpen, bg: "#FF8700" }, // `onClick` agora usa `onOpen` diretamente
    ];

    return (
        <>
            <Box display={{ base: "block", lg: "none" }} zIndex="8">
                <Flex
                    bg="#8dc42e"
                    width="100%"
                    height="50px"
                    padding="0 20px"
                    justifyContent="space-between"
                    align="center"
                    position={"fixed"}
                    zIndex={4}
                >
                    <Link href="#oecc">
                        <Image 
                            loading="lazy"
                            src="\logos\brancooecc_horizontal 1.svg"
                            w="160px"
                        />
                    </Link>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<HamburgerIcon color={"white"} />}
                            variant="outline"
                            backgroundColor={"#F5811E"}
                            borderStyle={"none"}
                            boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)"
                            _expanded={{ bg: "#F5811E" }}
                            _hover={{ bg: "#F5811E" }}
                            width="40px"
                            height="40px"
                            padding="0"
                        />
                        <MenuList bg="#8dc42e">
                            {menuItems.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    bg="#8dc42e"
                                    onClick={item.onClick} // `onClick` é adicionado aqui
                                >
                                    <Link
                                        href={item.href}
                                        _hover={{ textDecoration: "none" }}
                                        color="#ffffff"
                                        fontWeight={"bold"}
                                    >
                                        {item.label}
                                    </Link>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Flex>
            </Box>
            <LoginModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default MobileMenu;
