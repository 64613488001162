import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import CarrosselEdicoes from './CarrosselEdicoes';

const EdicoesAnteriores = () => {
    return (
        <Box id="edicoes" p={[4, 0]} marginLeft={{ base: '0', lg: '10%' }} marginRight={{ base: '0', lg: '10%' }} mb={10}>
            <VStack gap={10} justify="center" align={"center"}>
                <Heading fontSize={['48px', '64px']} color="#323C33" textAlign="center">
                    Edições Anteriores
                </Heading>
                <Text fontSize={['14px', '20px']} width={['100%', '75%', '80%']} textAlign={["justify", "center"]} pl={[6, 0]} pr={[6, 0]}>
                    O projeto foi desenvolvido em parceria com <strong>20 escolas</strong>, alcançando um total de mais de <strong>1000 inscrições</strong> de estudantes, dentre eles, <strong>200 finalistas</strong>, destacaram-se pelo seu desempenho e dedicação ao longo do programa.
                </Text>
                <CarrosselEdicoes />
            </VStack>
        </Box >
    );
};

export default EdicoesAnteriores;
