import React from 'react';
import { Box, Text, HStack, VStack } from '@chakra-ui/react';
import { MdLooks3, MdLooks4,MdLooks5, MdLooksOne, MdLooksTwo } from 'react-icons/md';

const Regras = () => {
    return (
        <VStack align="start" >
            <Text fontSize="16px">
                Para garantir a integridade e a equidade durante a realização da prova online, siga as regras abaixo:
            </Text>
            <HStack alignItems="flex-start">
                <Box as={MdLooksOne} size="36px" color="#F5811E" />
                <Box flex="1">
                    <Text align="justify">
                        Certifique-se de que seu dispositivo (computador, tablet ou smartphone) está conectado a uma rede de internet estável.
                    </Text>
                    <Text fontSize={"12px"} align="justify">
                        Verifique a bateria do dispositivo e mantenha-o carregado durante toda a duração da prova.
                    </Text>
                </Box>
            </HStack>

            <HStack alignItems="flex-start">
                <Box as={MdLooksTwo} size="36px" color="#F5811E" />
                <Box flex="1">
                    <Text align="justify">
                        Mantenha-se em um ambiente silencioso e livre de interrupções.
                    </Text>
                    <Text fontSize={"12px"} align="justify">
                        Evite conversar com outras pessoas durante a prova e não utilize materiais de consulta, a menos que explicitamente permitido.
                    </Text>
                </Box>
            </HStack>

            <HStack alignItems="flex-start">
                <Box as={MdLooks3} size="36px" color="#F5811E" />
                <Box flex="1">
                    <Text align="justify">
                        A prova deve ser realizada individualmente, sem a ajuda de terceiros.
                    </Text>
                    <Text fontSize={"12px"} align="justify">
                        Qualquer forma de trapaça ou desonestidade resultará na desclassificação imediata.
                    </Text>
                </Box>
            </HStack>

            <HStack alignItems="flex-start">
                <Box as={MdLooks4} size="36px" color="#F5811E" />
                <Box flex="1">
                    <Text align="justify">
                        Durante a prova, o uso de softwares de monitoramento pode ser utilizado para garantir a integridade do exame.
                    </Text>
                    <Text fontSize={"12px"} align="justify">
                        Certifique-se de que sua câmera e microfone estão funcionando corretamente, caso necessário.
                    </Text>
                </Box>
            </HStack>

            <HStack alignItems="flex-start">
                <Box as={MdLooks5} size="36px" color="#F5811E" />
                <Box flex="1">
                    <Text align="justify">
                        Leia todas as questões com atenção e gerencie bem o seu tempo.
                    </Text>
                    <Text fontSize={"12px"} align="justify">
                        O tempo total para a realização da prova é de 50 minutos. Não haverá prorrogação.
                    </Text>
                </Box>
            </HStack>
        </VStack>
    );
};

export default Regras;
