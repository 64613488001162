import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import LoginModal from "../Login/Login";

const Menu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <DesktopMenu onOpen={onOpen} />
            <MobileMenu onOpen={onOpen} />
            <LoginModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default Menu;
