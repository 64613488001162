import React from "react";
import {
    Box,
    Button,
    Link,
    ButtonGroup,
    useDisclosure,
    Image,
} from "@chakra-ui/react";
import LoginModal from "../Login/Login";

// Define a constante com as informações dos botões
const info_botoes = [
    { href: "#edicoes", text: "EDIÇÕES ANTERIORES" },
    { href: "#materialapoio", text: "MATERIAL DE APOIO" },
    { href: "#contato", text: "CONTATO" }
];

const DesktopMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Box
                bg="#8dc42e"
                position="fixed"
                width="100%"
                height="50px"
                padding="0 10%"
                display={{ base: "none", lg: "flex" }} // oculta em telas menores que lg (um pouco maiores que md)
                justifyContent="space-between"
                alignItems="center"
                zIndex="8"
            >
                <Link href="#oecc">
                    <Image
                        loading="lazy" src="\logos\brancooecc_horizontal 1.svg"
                        w='180px'
                        align={'center'}
                    />
                </Link>
                <ButtonGroup variant="outline" spacing="6" ml="auto">
                    {info_botoes.map(({ href, text }) => (
                        <Link key={href} href={href}>
                            <Button
                                variant="ghost"
                                color="#ffffff"
                                _hover={{
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                }}
                                borderRadius={"40px"}
                                fontWeight={"bold"}
                            >
                                {text}
                            </Button>
                        </Link>
                    ))}
                    <Button
                        variant="ghost"
                        bg={"#F5811E"}
                        color={"white"}
                        _hover={{
                            bgGradient: "linear(to-r, orange.300, orange.500)",
                        }}
                        borderRadius={"40px"}
                        onClick={onOpen}
                    >
                        LOGIN
                    </Button>
                </ButtonGroup>
            </Box>
            <LoginModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default DesktopMenu;
