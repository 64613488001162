import React from "react";
import { Box, Heading, Grid, useBreakpointValue } from "@chakra-ui/react";
import ObjetivosCards from "./ObjetivosCards";
import { FaBolt, FaBook, FaBrain, FaHandshake } from 'react-icons/fa';

const Objetivos = () => {
    // Define o número de colunas do grid com base no tamanho da tela
    const gridTemplateColumns = useBreakpointValue({ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' });

    return (
        <Box align="center" marginLeft={"10%"} marginRight={"10%"}>
            <Heading fontSize={['28px', '42px']} color="#323C33" mb={10}>
                Objetivos
            </Heading>
            <Grid
                templateColumns={gridTemplateColumns}
                gap={4}  // Espaçamento entre os cartões
                p={4}   // Padding ao redor do grid
            >
                <ObjetivosCards icone={FaBolt} descricao={"Estimular alunos do ensino fundamental quanto ao uso racional e eficiente dos recursos naturais, tornando-os multiplicadores da responsabilidade social e ambiental."} />
                <ObjetivosCards icone={FaHandshake} descricao={"Estabelecer um elo de cooperação mútua entre instituições de ensino da educação básica de Campina Grande e a UFCG, através de ações de extensão universitária para troca de saberes."} />
                <ObjetivosCards icone={FaBook} descricao={"Envolver e beneficiar estudantes regularmente matriculados nos anos finais do Ensino Fundamental (8º e 9º ano) em escolas do estado da Paraíba, sejam elas públicas ou privadas."} />
                <ObjetivosCards icone={FaBrain} descricao={"Estimular o conhecimento científico como ferramenta de transformação social e como campo para o desenvolvimento de soluções que estimulem a responsabilidade social e ambiental."} />
            </Grid>
        </Box>
    );
};

export default Objetivos;