import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ConfirmarEnvio = ({ isOpen, onClose, onConfirm }) => {
    const toast = useToast();
    const navigate = useNavigate();

    const handleConfirm = () => {
        onConfirm();
        toast({
            title: "Prova enviada com sucesso!",
            description: "Obrigado por enviar a sua prova.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        onClose();
        navigate('/home-prova');
    };

    return ( 
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent  margin='4'>
                <ModalHeader>Confirmar Envio</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Tem certeza de que deseja enviar a prova?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
                        Enviar
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancelar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmarEnvio;
