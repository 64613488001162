import React from "react";
import { Card, CardHeader, CardBody, Icon, Text, Box } from "@chakra-ui/react";

const ObjetivosCards = ({ icone, descricao }) => {
    return (
        <Card
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.3)"
            border="solid"
            borderColor="#FFC727"
            backgroundColor="white"
            align="center"
            p={8}
            width="100%"
            height="100%"
            transition="transform 0.3s, box-shadow 0.3s"
            _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.6)",
                backgroundColor: "#FFFCC3"
            }}
            display="flex"
            flexDirection="column"  // Alinha os filhos verticalmente
            justifyContent="center" // Centraliza os filhos verticalmente
            alignItems="center"     // Centraliza os filhos horizontalmente
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bg="#FFC727"
                width={{ base: '2.5em', sm: '3em' }}
                height={{ base: '2.5em', sm: '3em' }}
            >
                <Icon as={icone} w={{ base: 5, sm: 6 }} h={{ base: 5, sm: 6 }} color="white" />
            </Box>
            <Text
                fontSize={{ base: '10px', md: '12px', lg: '15px' }}
                textAlign="center"
            >
                {descricao}
            </Text>
        </Card>
    );
};

export default ObjetivosCards;