import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

const Menu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <MobileMenu />
            <DesktopMenu onOpen={onOpen} />
        </>
    );
};

export default Menu;
