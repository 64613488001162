import './App.css';
import "./Reset.css";
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import Home from './components/HomePage/Home';
import Prova from './components/Prova/Prova';
import theme from './theme';
import EnvioRealizado from './components/HomePage/EnvioRealizado';
import { useAutenticacao } from './components/Login/hook';

function App() {
  const { logado } = useAutenticacao();

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/home"
          element={logado ? <Home /> : <Navigate to="/" />}
        />
        <Route
          path="/prova"
          element={logado ? <Prova /> : <Navigate to="/" />}
        />
        <Route
          path="/home-prova"
          element={logado ? <EnvioRealizado /> : <Navigate to="/" />}
        />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
