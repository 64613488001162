import React, { useState, useEffect } from 'react';
import { Box, Button, VStack, Flex, Text, useDisclosure } from '@chakra-ui/react';
import Questao from './Questao/Questao';
import ConfirmarEnvio from './ConfirmarEnvio';

const Prova = () => {
    const questions = [
        {
            "statement": "O que é eficiência energética?",
            "options": ["Uso eficaz de energia", "Geração de energia", "Redução de energia", "Consumo de energia"],
        },
        {
            "statement": "Qual dos seguintes métodos é considerado uma prática de eficiência energética em residências?",
            "options": ["Uso de lâmpadas LED", "Manter as luzes acesas o tempo todo", "Uso de aparelhos antigos", "Deixar janelas abertas no inverno"],
            "media": "https://www.epe.gov.br/sites-pt/abcdenergia/PublishingImages/Efici%C3%AAncia%20Energ%C3%A9tica/06-%20casa.png"
        },
        {
            "statement": "Qual é o principal benefício da eficiência energética?",
            "options": ["Redução de custos de energia", "Aumento da produção de energia", "Maior consumo de energia", "Aumento da poluição"],
            "media": "https://www.youtube.com/watch?v=Nr7aL2KLZe4"
        },
        {
            "statement": "Qual dos seguintes é um exemplo de energia renovável?",
            "options": ["Energia solar", "Carvão", "Gás natural", "Petróleo"],
        },
        {
            "statement": "Qual é o impacto ambiental da eficiência energética?",
            "options": ["Redução das emissões de CO2", "Aumento das emissões de CO2", "Maior uso de combustíveis fósseis", "Desperdício de recursos naturais"],
            "media": ""
        }
    ];

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [timeLeft, setTimeLeft] = useState(50 * 60); // 50 minutes in seconds
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (timeLeft <= 0) {
            alert('O tempo acabou!');
            setTimeLeft(0);
        }
    }, [timeLeft]);

    const handleNextQuestion = () => {
        if (selectedOption) {
            if (currentQuestionIndex === questions.length - 1) {
                onOpen(); // Open the modal when the last question is answered
            } else {
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            }
            setSelectedOption('');
        }
    };

    const handleConfirmSubmit = () => {
        // Handle additional submission logic if needed
        // For example, you can perform form submission or API call here
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const getTimeColor = () => {
        if (timeLeft <= 10 * 60) {
            return 'red.500';
        }
        return 'white';
    };

    const getRemainingQuestionsText = () => {
        const remainingQuestions = questions.length - currentQuestionIndex - 1;
        if (remainingQuestions === 0) {
            return "Esta é a última questão";
        }
        return `Faltam ${remainingQuestions} questões`;
    };

    return (
        <Box height="100vh" p={[8, 12]} bgColor={'#f0f0f0'} justifyContent="center" alignItems="center">
            <VStack h={'90%'}>
                <Flex justify="space-between" w="90%" direction={['column', 'row']} textAlign={'center'}>
                    <Text fontSize={["s", "xl"]} bg="#F5811E" p={'2'} color={getTimeColor()} borderRadius="10px" marginBottom={['2', '0']}>
                        Tempo restante: {formatTime(timeLeft)}
                    </Text>
                    <Text fontSize={["s", "xl"]} bg="#F5811E" p={'2'} color="white" borderRadius="10px">
                        {getRemainingQuestionsText()}
                    </Text>
                </Flex>
                <Questao
                    question={questions[currentQuestionIndex]}
                    questionNumber={currentQuestionIndex + 1}
                    selectedOption={selectedOption}
                    onOptionChange={setSelectedOption}
                />
                <Flex w="90%" justify="flex-end">
                    <Button
                        bg="#F5811E"
                        color="white"
                        borderRadius="40px"
                        _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                        onClick={handleNextQuestion}
                        isDisabled={!selectedOption}
                    >
                        {currentQuestionIndex === questions.length - 1 ? 'Finalizar Prova' : 'Próxima Questão'}
                    </Button>
                </Flex>
            </VStack>

            <ConfirmarEnvio isOpen={isOpen} onClose={onClose} onConfirm={handleConfirmSubmit} />
        </Box>
    );
};

export default Prova;
