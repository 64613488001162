import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useAutenticacao = () => {
    const navigate = useNavigate();

    const [logado, setLogado] = useState(() => {
        const status = localStorage.getItem("logado") === "true";
        console.log("Estado inicial de logado:", status); // Debug
        return status;
    });

    const login = () => {
        localStorage.setItem("logado", "true");
        setLogado(true);
        console.log("Usuário logado."); // Debug
        navigate('/home');
    };

    const logout = () => {
        localStorage.removeItem("logado");
        setLogado(false);
        console.log("Usuário deslogado."); // Debug
    };

    return {
        logado,
        login,
        logout
    };
};
