import React from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import ContaQuestao from './ContaQuestoes';
import Opcoes from './Opcoes';
import MidiaQuestao from './MidiaQuestao';


const Questao = ({ question, questionNumber, selectedOption, onOptionChange }) => (
    <Box
        p={4}
        shadow="md"
        borderRadius="md"
        bgColor={'white'}
        alignSelf={'center'}
        height={'90%'} // Ajusta a altura para telas menores e maiores
        width={'90%'} // Ajusta a largura para telas menores e maiores
    >
        <Flex direction="column" align={'flex-start'}>
            <ContaQuestao
                questionNumber={questionNumber}
                statement={question.statement}
                media={question.media}
            />
            {question.media ? (
                <Flex direction={{ base: "column", md: "row" }} gap={4} align={'center'}>
                    <Box flex="1">
                        <MidiaQuestao media={question.media} />
                    </Box>
                    <Flex direction="column" flex="1">
                        <Opcoes
                            options={question.options}
                            selectedOption={selectedOption}
                            onOptionChange={onOptionChange}
                        />
                    </Flex>

                </Flex>
            ) : (
                <Flex direction="column"  >
                    <Opcoes
                        options={question.options}
                        selectedOption={selectedOption}
                        onOptionChange={onOptionChange}
                    />
                </Flex>
            )}
        </Flex>
    </Box>
);

export default Questao;
