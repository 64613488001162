import {
    Box,
    Button,
    HStack,
    Heading,
    Image,
    VStack,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

import { useNavigate } from 'react-router';
import Menu from './Menu';
import Regras from './Regras';
import VideoModal from './VideoModal';

const Home = () => {
    const navigate = useNavigate(); // Usar useNavigate para a navegação
    const [tutorialCompleto, setTutorialCompleto] = useState(false);
    const [alertaAberto, setAlertaAberto] = useState(false);
    const cancelRef = useRef();

    //Gerencia o estado do modal
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isAlertOpen,
        onOpen: onAlertOpen,
        onClose: onAlertClose,
    } = useDisclosure(); // Gerencia o estado do alerta de tutorial

    const handleProva = () => {
        if (tutorialCompleto) {
            navigate('/prova');
        } else {
            setAlertaAberto(true); //Mostra o pop-up se o tutorial não foi acessado
            onAlertOpen();
        }
    };

    const handleTutorial = () => {
        setTutorialCompleto(true);
        onOpen(); //Abre o modal de vídeo
    };

    //const 

    return (
        <Box bgColor={'#f0f0f0'} minHeight="170vh">
            <Menu />
            <Box display="flex" flexDirection="column" alignItems="center" p={[8, 12]} >
                <Box textAlign="center" mt={{ base: 10, md: 40 }}>
                    <Heading fontSize={{ base: '30px', md: '50px' }} color="#323C33" marginBottom={'10'}>
                        Seja bem vindo(a) à Olimpíada Educacional do Consumo Consciente
                    </Heading>
                    <HStack gap={10} justify="center" flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
                        <Image
                            loading="lazy" src="/imagens/Online test-amico.svg" // Certifique-se de usar / em vez de \
                            w={{ base: '100%', md: '600px', lg: '450px' }}  // Ajuste o tamanho da imagem para ocupar a largura total em telas pequenas e um tamanho fixo em telas maiores
                            mb={{ base: '4', md: '0' }}  // Margem inferior para separar a imagem do texto em telas pequenas
                        />
                        <VStack spacing={8} align="center" w="100%">
                            <Regras />
                            <HStack
                                spacing={{ base: 1, md: 8 }}
                                flexDirection={{ base: 'column', md: 'row' }} // Coloca os botões lado a lado em telas médias e grandes
                                w="100%"
                                justify="center"
                            >
                                <Button
                                    bg="#F5811E"
                                    color="white"
                                    borderRadius="40px"
                                    mt={{ base: 4, lg: 2 }} // Adiciona margem superior na versão desktop
                                    _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                                    w={'20em'}
                                    onClick={handleProva}
                                >
                                    Iniciar prova
                                </Button>
                                <Button
                                    bg="#F5811E"
                                    color="white"
                                    borderRadius="40px"
                                    mt={{ base: 4, lg: 2 }} // Adiciona margem superior na versão desktop
                                    _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                                    w={'20em'}
                                    onClick={handleTutorial}
                                >
                                    Iniciar Tutorial
                                </Button>
                            </HStack>
                        </VStack>
                    </HStack>
                </Box>
            </Box>
            <VideoModal isOpen={isOpen} onClose={onClose}></VideoModal>

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onAlertClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Tutorial Incompleto
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Você precisa assistir ao tutorial antes de iniciar a prova. Por favor, clique em "Iniciar Tutorial" para continuar.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onAlertClose}>
                                Ok
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default Home;