import React, { useState } from 'react';
import { HStack, Flex, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import NavigationButton from './NavigationButton';
import Slide from './Slide';
import imagens from './imagens'; // ou './images.json'

const CarrosselEdicoes = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = imagens.length;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  return (
    <Flex direction="column" align="center" w="100%" mx="auto">
      <HStack alignItems="center" w="100%" justifyContent="center" spacing={[2, 4, 6]}>
        <NavigationButton direction="left" onClick={prevSlide} icon={<ChevronLeftIcon fontSize={['40px', '50px', '70px']} color="#F5811E" />} />
        <Flex justify="center" align="center" position="relative" w={['90%', '80%', '70%']} height={['300px', '400px', '500px']} overflow="hidden">
          {imagens.map((image, index) => (
            <Slide key={index} image={image} index={index} currentIndex={currentIndex} totalSlides={totalSlides} />
          ))}
        </Flex>
        <NavigationButton direction="right" onClick={nextSlide} icon={<ChevronRightIcon fontSize={['40px', '50px', '70px']} color="#F5811E" />} />
      </HStack>
      <Text mt={2} textAlign="center" whiteSpace="pre-wrap" fontWeight="bolder" fontSize={['12px', '20px']} zIndex={2}>
        {imagens[currentIndex].legenda}
      </Text>
    </Flex>
  );
};

export default CarrosselEdicoes;
