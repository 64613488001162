import React, { useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Slide from './Slide';

const text = [
    { caption: 'Equipamentos eficientes:\nNa hora de comprar um aparelho eletrônico, escolha sempre os que vêm com o selo Procel de economia de energia. O selo indica quais os aparelhos mais eficientes no consumo. A escala varia de "A" a "G", sendo mais eficientes os que têm a classificação "A".' },
    { caption: 'Reduza o desperdício:\nQuanto maior o desperdício de energia, maior o valor da "conta de luz" no final do mês. Ao usar a energia elétrica de forma racional, poupamos nosso dinheiro e ajudamos a economizar recursos da natureza usados na sua produção.' },
    { caption: 'Hábitos inteligentes:\nUtilizar os equipamentos elétricos de forma equilibrada, para evitar o consumo excessivo de energia.' },
    { caption: 'Projetos inteligentes:\nNo projeto de uma casa já podemos utilizar soluções criativas que vão nos ajudar a economizar energia, como ambientes que aproveitam melhor a luz natural. Nossos hábitos de consumo também podem ajudar na economia, com simples mudanças como manter a geladeira aberta por menos tempo.' },
    { caption: 'Iluminação eficiente:\nTroque lâmpadas incandescentes por lâmpadas LED. Elas consomem até 80% menos energia e têm uma vida útil muito mais longa, resultando em economia e menos resíduos.' },
    { caption: 'Uso consciente do ar-condicionado:\nMantenha o termostato do ar-condicionado em uma temperatura moderada, entre 22°C e 24°C. Isso pode reduzir significativamente o consumo de energia e aumentar a durabilidade do aparelho.' },
    { caption: 'Desligue aparelhos em standby:\nAparelhos em modo standby ainda consomem energia. Desconecte dispositivos eletrônicos quando não estiverem em uso para evitar o consumo desnecessário.' },
    { caption: 'Uso eficiente da água:\nInstale aeradores em torneiras e chuveiros para reduzir o consumo de água. Menos água quente significa menor consumo de energia para aquecimento.' },
    { caption: 'Manutenção regular:\nRealize manutenção regular em seus aparelhos elétricos e sistemas de aquecimento/refrigeração. Isso garante que eles funcionem com eficiência e ajudam a evitar o desperdício de energia.' },
    { caption: 'Utilize a energia de forma inteligente:\nAproveite a luz natural durante o dia para reduzir a necessidade de iluminação artificial. Abra cortinas e persianas para iluminar os ambientes com luz natural.' },
];

const Carrossel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slidesPerPage = useBreakpointValue({ base: 1, md: 3, lg: 4 });
    const totalSlides = text.length;
    const totalPages = Math.ceil(totalSlides / slidesPerPage);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalPages);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalPages) % totalPages);
    };

    const startIndex = currentIndex * slidesPerPage;
    const currentSlides = text.slice(startIndex, startIndex + slidesPerPage);

    return (
        <Flex direction="column" align="center" w="100%" maxW="1200px" m="0 auto">
            <Flex 
                overflowX="hidden" 
                w="100%" 
                px={4} 
                py={2} 
                position="relative"
            >
                <Flex 
                    w={`${totalSlides * (100 / slidesPerPage)}%`} 
                    flexDirection="row" 
                    transition="transform 0.7s ease"
                    transform={`translateX(-${(currentIndex * 100) / totalPages}%)`}
                >
                    {text.map((image, index) => (
                        <Box
                            key={index}
                            w={`${100 / slidesPerPage}%`} // Cada slide ocupa uma fração do container
                            p={2}
                            boxSizing="border-box"
                        >
                            <Slide image={image} />
                        </Box>
                    ))}
                </Flex>
            </Flex>
            <Flex mt={4}>
                <ChevronLeftIcon onClick={prevSlide} fontSize={'26px'} color="#F5811E" cursor="pointer" />
                <ChevronRightIcon onClick={nextSlide} fontSize={'26px'} color="#F5811E" cursor="pointer" />
            </Flex>
        </Flex>
    );
};

export default Carrossel;
