// src/components/Questao/MidiaQuestao.js
import React from 'react';
import { Box, Image, AspectRatio } from '@chakra-ui/react';
import YouTube from 'react-youtube';

const isYouTubeVideo = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
};

const getYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
};

const MidiaQuestao = ({ media }) => {
    if (isYouTubeVideo(media)) {
        return (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                <YouTube
                    width='100%'
                    height='100%'
                    videoId={getYouTubeId(media)}
                    opts={{
                        playerVars: {
                            autoplay: 0,
                            controls: 1,
                            modestbranding: 1,
                            rel: 0,
                        },
                    }}
                />
            </Box>
        );
    } else {
        return (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                <Image src={media} alt="Mídia da questão" width="100%" maxW="100%" />
            </Box>
        );
    }
};

export default MidiaQuestao;
